export const enviarFormulario = async (formData) => {
    try {
      const response = await fetch('https://hook.us1.make.com/llinz6z5s6ommmu19av7b3jnmwd2czna', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Error al enviar el formulario');
      }
  
      return true; // Indica éxito
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      return false; // Indica fallo
    }
  };
  